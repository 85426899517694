import React from 'react';
import Header from '../companyPresentation/header/header';
import './BlogPost.css';


function BlogPost(props) {
    console.log(props);
    // Safety checks for extracting the post object from the location state
    const post = props.location && props.location.state ? props.location.state.post : null;
    const blogServiceUrl = post ? post.url : 'http://localhost:8000/blog/default';

    return (
        <div>
            <Header />
            <div className="blog-post-wrapper">
            <iframe 
                src={blogServiceUrl} 
                className='blog-post-iframe'
                frameBorder="0" 
                title={`Blog Post ${post ? post.id : 'default'}`}
            ></iframe>
            </div>
        </div>
    );
}

export default BlogPost;
