const prod = {
    url: {
        BLOG_SERVICE_URL: "https://blog-service.kudostech.ro",
        CONTACT_US_URL: 'https://kudostech.ro/send-email'
    }
}

const local = {
    url: {
        BLOG_SERVICE_URL: "http://localhost:8000",
        CONTACT_US_URL: 'http://localhost:3000/send-email'
    }
}

const environments = {
    local: local,
    prod: prod
};
export const config = environments[process.env.REACT_APP_ENV] || local;