import React from 'react';
import Navbar from "./navbar/navbar";
import "./header.css";

function Header(){
    return(
        <div className="container-fluid header-section" id="header">
             <div className="container" id="header-container">
                <div className="main-header">
                    <Navbar/>
                </div>
            </div>
        </div>   
    )
}

export default Header;