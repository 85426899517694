import React, { Component } from 'react';
import { BrowserRouter, Route} from "react-router-dom";
import './App.css';
import CompanyPresentation from "./components/companyPresentation/companyPresentation"
import Blog from "./components/blog/Blog"
import BlogPost from './components/blog/BlogPost';

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Route exact path="/" component={CompanyPresentation}/>
          <Route exact path="/blog" component={Blog}/>
          <Route exact path="/blog/:id" component={BlogPost}/>
        </BrowserRouter>
    );
  }
}

export default App;
