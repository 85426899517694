import React, {useState} from "react";
import "./contact.css";
import { sendEmail } from './sendEmail';

function Contact() {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(firstName, lastName, email, subject, message);
    sendEmail(firstName, lastName, email, subject, message);
    setFirstName("");
    setLastName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <React.Fragment>
      <div className="container-fluid contact-section" id="contact">
        <div className="container">
          <div className="contact-text">
            <h2>Contact us</h2>
            <p>
              Our team is happy to answer your questions. Fill out the form and
              we'll be in touch as soon as possible.
            </p>
          </div>
          <div className="contact-touch row">
            <div className="get-in-touch-form">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-6 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                      required
                    />
                  </div>
                  <div className="col-xl-6 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                      required
                      />
                  </div>
                  <div className="col-xl-6 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      value={subject}
                      onChange={(event) => setSubject(event.target.value)}
                        required
                    />
                  </div>
                  <div className="col-xl-6 col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                    />
                  </div>
                  <div className="col-xl-12 col-12">
                    <textarea
                      className="form-control"
                      placeholder="Your message"
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                        required
                    />
                  </div>
                  <div className="col-xl-12 col-12 button-container">
                    <button className="submit-button" type="submit">
                      Send message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Contact;
