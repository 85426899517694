import React from "react";
import "./footer.css";
import ContactOption from "../contact/contactOption/contactOption";

function Footer() {
  let email = (
    <a href="mailto:contact@kudostech.ro" style={{ color: "white" }}>
      contact@kudostech.ro
    </a>
  );
  let data = [
    {
      icon: "fa fa-location-arrow",
      type: "Address",
      contact: "57A Calea Dorobantilor, Cluj-Napoca | Romania",
    },
    {
      icon: "fa fa-envelope-o",
      type: "Email",
      contact: email,
    },
    {
      icon: "fa fa-phone",
      type: "Phone",
      contact: "+40 745 628 522",
    },
  ];
  return (
    <div className="container-fluid footer-section" id="contact">
      <div className="container">
      <div className="col-xl-4 col-md-4">
            <div className="contact-info">
              {data.map((d, index) => (
                <ContactOption
                  key={index}
                  icon={d.icon}
                  type={d.type}
                  contact={d.contact}
                />
              ))}
            </div>
          </div>
        </div>
        {/* <div className="row footer-contacts-container">
          <div className="get-in-touch col-xl-6 col-md-6 col-12">
            <h3>Get in touch</h3>
            <p>
              Follow us on social media to keep up to date with all our news and
              do not hesitate to contact us using any contact info displayed
              near by.
            </p>
            <div className="social">
              <div className="logo">
                <img
                  src={require("../../../images/logo_kudos_text_white.png")}
                />
              </div>
              <div className="icons">
                            <a href="https://www.facebook.com/kudostech.ro/" target="_blank"><i className="fa fa-facebook"></i></a>
                            <a href="https://www.instagram.com/kudos.tech/" target="_blank"><i className="fa fa-instagram"></i></a>
                            <a href="https://www.linkedin.com/company/kudos-technologies/" target="_blank"><i className="fa fa-linkedin-square"></i></a>
                        </div>
            </div>
          </div>

        </div> */}
      </div>
    // </div>
  );
}

export default Footer;
