import React from "react";
import "./about.css";
import KudosDevCycleWeb from "../../../images/kudos_dev_cycle_web.svg";
import KudosDevCycleMobile from "../../../images/kudos_dev_cycle_mobile.svg";

function About() {
  return (
    <div className="container-fluid about-section" id="about">
      <div className="container">
        <div className="about-content row">
          <div className="col-xl-12 col-12 about-description">
            <h2 className="about-h2">Who we are</h2>
            {/*<h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>*/}
            <p>
              Kudos Technologies is more than just your IT consulting and
              digital product development partner. Our vision is to maximize
              value, cultivate innovation, and foster fresh ideas. Nestled in
              the evolving landscape of technology, our young and dynamic team
              is dedicated to delivering exceptional service and solutions with
              a smile.
            </p>
            <p>
              Our expertise spans across backend and frontend technologies,
              making us a one-stop solution for businesses looking for
              comprehensive IT services. Whether you're a small business looking
              to go digital, or a large company needing tech advice, we've got a
              variety of services and tools to help with all sorts of
              development projects.
            </p>
            <p>
              In short, at Kudos Technologies, we're here to make your life
              easier by handling your technology needs. Let's team up and bring
              your ideas to life!
            </p>
          </div>
          <div className="col-xl-12 col-12 about-image-wrapper">
            <img
              className="about-image"
              src={KudosDevCycleWeb}
              alt="aboutGym"
            />
            <img
              className="about-image-mobile"
              src={KudosDevCycleMobile}
              alt="aboutGym"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
