import axios from "axios"
import { config } from '../../Constants.js';


export const sendEmail = async (firstName, lastName, email, subject, message) => {
    const data = {
      firstName,
      lastName,
      email,
      subject,
      message
    };
  
    try {
      const response = await axios.post(config.url.CONTACT_US_URL, data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };