import React from "react";
import "./services.css";
import Service from "./service/service";

function Portofolio() {
  let data = [
    {
      icon: "settings.png",
      category: "Back-end development",
      description:
        "We build the powerhouse behind your digital product. Our team ensures smooth data management, user authentication, and robust server-side logic.",
      logos: ["java_icon.png", "node_icon.png", "python_icon.png"],
    },
    {
      icon: "web.png",
      category: "Front-end Development",
      description:
        "Crafting intuitive and engaging user interfaces is our specialty. We make your digital platform visually stunning, easy-to-navigate, and highly responsive.",
      logos: ["react_icon.png", "angular_icon.png", "javascript_icon.png", "html_icon.png", "css_icon.png"],
    },
    {
      icon: "mobile.png",
      category: "Mobile development",
      description:
        "Transform your business with our tailored mobile solutions. We create user-friendly, high-performing mobile apps for a seamless on-the-go experience.",
      logos: ["flutter_icon.png", "android_icon.png", "ios_icon.png"],
    }
  ];
  return (
    <div className="container-fluid services-section" id="services">
      <div className="container">
        <div className="services-description">
          <h2>Our Services</h2>
        </div>
        <div className="services">
          {data.map((d, index) => (
            <Service
              key={index}
              category={d.category}
              description={d.description}
              icon={d.icon}
              logos={d.logos}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Portofolio;
