import React from "react";
import "./contactOption.css";

function ContactOption(props){
    return(
        <div className="contact">
            <div className="contact-option">
                <span className="icon">
                    <i className={props.icon}></i>
                </span>
                <div className="content">
                    <h4>{props.type}</h4>
                    <p>{props.contact}</p>
                </div>
            </div>
        </div>
    )
}

export default ContactOption;