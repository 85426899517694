import React from 'react';
import "./team.css";
import "./member/member";
import Member from './member/member';

function Team() {
    let data=[
        {   
            linkedin:"https://www.linkedin.com/in/andrei-rad-08a244b2/",
            image:require("../../../images/team_members_avatars/arad.png"),
            name:"Rad Andrei",
            function:"Founder"
        },
        {   
            linkedin:"https://www.linkedin.com/in/tudor-patrutiu/",
            image:require("../../../images/team_members_avatars/tpatrutiu.png"),
            name:"Tudor Patrutiu",
            function:"Backend Developer"
        },
        {   
            linkedin:"https://www.linkedin.com/in/ovidiu-iuonas-0a2966106/",
            image:require("../../../images/team_members_avatars/oiuonas.png"),
            name:"Ovidiu Iuonas",
            function:"Mobile Developer"
        }
    ]; 
    return ( 
        <div className="container-fluid team-section" id="team">
            <div className="container">
                <div className="team-description">
                    <h2>Team</h2>
                </div>
                <div className="row">
                    {data.map((d,index) => <Member key={index} image={d.image} name={d.name} linkedin={d.linkedin} function={d.function}/>)}
                </div>
            </div>
        </div>
    )
}
 
export default Team;