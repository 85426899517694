import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../Constants';
import { Link } from 'react-router-dom';
import Header from '../companyPresentation/header/header';  // Adjust the path if necessary.
import './Blog.css';

function Blog() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(config.url.BLOG_SERVICE_URL + '/blog')
      .then(response => {
        setPosts(response.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div>
        <Header />
        <div>Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Header />
        <div>Error: {error}</div>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="container mt-5">
        {posts.map(post => (
          <Link to={{ pathname: `/blog/${post.id}`, state: { post } }} key={post.id} style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="blog-post-item">
              <div className="blog-post-content">
                <h2 className="blog-post-title">{post.title}</h2>
                <p className="blog-post-preview">{post.description}</p>
                <p className="blog-post-author">{post.author}</p>
              </div>
              <div className="blog-post-image">
                <img src={post.preview_image_url} alt={post.title} />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Blog;
